.notification {
  position: fixed;
  top: 80px; /* Adjust based on header height */
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  z-index: 1000;
  animation: slideIn 0.3s ease-out;
  min-width: 300px;
}

.notification.success {
  background: #e8f5e9;
  color: #2e7d32;
}

.notification.success button {
  color: #2e7d32;
}

.notification.error {
  background: #ffebee;
  color: #c62828;
}

.notification.error button {
  color: #c62828;
}

.notification span {
  text-align: left;
  flex: 1;
}

.notification button {
  width: 24px;
  height: 24px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  cursor: pointer;
  font-size: 18px;
  border-radius: 4px;
}

.notification button:hover {
  background: rgba(0,0,0,0.1);
}