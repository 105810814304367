.request-list {
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
}

.request-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.request-item {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 10px;
}

.badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
}

.badge.feature {
  background: #4CAF50;
  color: white;
}

.badge.bug {
  background: #f44336;
  color: white;
}

.badge.customization {
  background: #0000FF;
  color: white;
}

.product-label {
  font-size: 0.9em;
  color: #666;
  background: #f0f0f0;
  padding: 4px 8px;
  border-radius: 4px;
}