.request-form {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
}

.form-group {
  margin-bottom: 15px;
}

label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

select, textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-top: 5px;
}

textarea {
  min-height: 100px;
  resize: vertical;
}

button {
  background-color: #0066cc;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0052a3;
}

.form-group input[type="file"] {
  padding: 10px;
  border: 1px dashed #ddd;
  border-radius: 4px;
  width: 100%;
  background: #f9f9f9;
}

.form-group small {
  display: block;
  color: #666;
  margin-top: 4px;
  font-size: 0.8em;
}