.change-password {
  max-width: 400px;
  margin: 20px auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.error-message {
  color: #c62828;
  margin-bottom: 10px;
}

.success-message {
  color: #2e7d32;
  margin-bottom: 10px;
}

button {
  width: 100%;
  padding: 10px;
  background: #01B0BF;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}