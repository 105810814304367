@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');

:root {
  --font-primary: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
}

body {
  font-family: var(--font-primary);
}

.App {
  text-align: center;
  min-height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.request-list {
  margin-top: 2rem;
  padding: 1rem;
}

.request-item {
  background: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 1rem;
  margin-bottom: 1rem;
}

.request-item h3 {
  color: #333;
  margin-bottom: 0.5rem;
}

.request-item small {
  color: #666;
  display: block;
  margin-top: 0.5rem;
}

.request-form {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.form-group {
  margin-bottom: 20px;
  width: 100%;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.form-group input,
.form-group select,
.form-group textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  font-family: var(--font-primary);
  box-sizing: border-box;
}

.form-group textarea {
  min-height: 120px;
  resize: vertical;
}

.form-group select {
  background: white;
  cursor: pointer;
}

.form-group input:focus,
.form-group select:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #01b0bf;
  box-shadow: 0 0 0 2px rgba(1, 176, 191, 0.1);
}

.submit-button {
  background: #01b0bf;
  color: white;
  padding: 12px 24px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.2s;
}

.submit-button:hover {
  background: #019aaa;
}

.submit-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

nav {
  background: #01b0bf;
  padding: 1rem;
  margin-bottom: 2rem;
}

nav a {
  color: white;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 1rem;
}

nav a:hover {
  background: #444;
  border-radius: 4px;
}

.nav-bar {
  background: #01b0bf;
  padding: 1rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  font-family: var(--font-primary);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  padding: 8px 16px;
  border-radius: 4px;
}

.nav-links a:hover {
  background: rgba(255, 255, 255, 0.1);
}

.nav-bar a {
  color: white !important;
  text-decoration: none;
  padding: 0.5rem 1rem;
  margin: 0 0.5rem;
  border-radius: 4px;
}

.nav-bar button {
  background: #296b9a !important;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
  margin-left: auto;
  width: auto !important;
}

.nav-bar a:hover {
  background: #444;
}

.nav-bar button:hover {
  background: #444 !important;
}
