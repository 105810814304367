.home-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 2rem;
}

.home-title {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

.form-container {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}