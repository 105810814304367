.request-id {
  font-family: monospace;
  color: #666;
  font-size: 1.1em;
  font-weight: 500;
  margin: 0;
}

.request-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.request-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header-left, .header-right {
  display: flex;
  align-items: center;
  gap: 8px;
}

.type-badge,
.status {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8em;
  text-transform: capitalize;
}

.type-badge.feature { background: #4adffe; color: #004257; }
.type-badge.bug { background: #7764b5; color: #ffffff; }
.type-badge.customization { background: #4bffc4; color: #0a513a; }

.product-label {
  padding: 4px 8px;
  border-radius: 4px;
  background: #f0f0f0;
  color: #666;
  font-size: 0.9em;
}

.description {
  margin: 10px 0;
  line-height: 1.5;
  text-align: left;
  padding: 20px 0;
}

.meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  font-size: 0.9em;
  color: #666;
}

.status.pending { background: #fff3e0; color: #e65100; }
.status.ongoing { background: #f5f5f5; color: #616161; }
.status.rejected { background: #ffebee; color: #c62828; }
.status.done { background: #e8f5e9; color: #2e7d32; }

.status-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.comments-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid #eee;
  text-align: left;
}

.comment {
  background: #f9f9f9;
  padding: 0.5rem;
  margin: 0.5rem 0;
  border-radius: 4px;
  text-align: left;
}

.comment p {
  margin: 0 0 0.5rem 0;
  text-align: left;
}

.comment small {
  color: #666;
  font-size: 0.8em;
}

.attachments {
  margin-top: 1rem;
  padding: 0.5rem;
  background: #f5f5f5;
  border-radius: 4px;
}

.attachment-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.25rem 0;
}

.attachment-icon {
  cursor: pointer;
  color: #666;
  font-size: 1.2rem;
  transition: color 0.2s ease;
}

.attachment-icon:hover {
  color: #0056b3;
}
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow: hidden;
}

.modal-content {
  position: relative;
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  max-height: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.preview-media {
  max-width: 100%;
  max-height: 80vh;
  border-radius: 8px;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  background: #ff4444;
  color: white;
  border: none;
  font-size: 18px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background 0.3s ease-in-out;
}

.close-button:hover {
  background: #cc0000;
}
